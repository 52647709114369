<template>
  <v-row>
    <v-col>
      <s-crud
        :single-row="false"
        add
        addNoFunction
        @add="addWorker()"
        searchInput
        @rowSelected="selectedWorker($event)"
        ref="gridWorker"
        title="Trabajadores"
        :config="config"
        :filter="filterWorker"
        @doubleClick="assignWorker($event)"
      >
        <template v-slot:filter>
          <v-container>
            <v-row justify="center"
              ><v-col lg="6"> <s-select-area v-model="filterWorker.AreID" label="Área"/></v-col
            ></v-row>
          </v-container>
        </template>
      </s-crud>
    </v-col>
    <v-col>
      <s-toolbar
        label="Impresión Fotocheck"
        remove
        :search="false"
        print
        @print="printEvent()"
        
        @removed="removeEvent($event)"
      ></s-toolbar>
      <v-card>
        <v-data-table
          dense
          @click:row="rowClick"
          selectable-key="isSelectable"
          v-model="selected"
          :headers="configPanel.headers"
          item-key="PrsDocumentNumber"
          :items="itemsCard"
        ></v-data-table>
      </v-card>
    </v-col>

    <v-col cols="12">
      <img src="" height="200" alt="Image preview...">
    </v-col>
  </v-row>
  
</template>

<script>
import _sCardService from "../../services/HumanResource/CardService";
export default {
  data() {
    return {
      selected: [],
      itemsCard: [],
      selectionBefore: [],
      filterWorker: { AreID: 0 },
      config: {
        service: _sCardService,
        model: {
          PrsDocumentNumber: "ID",
        },
        headers: [
          { text: "Documento", value: "PrsDocumentNumber", sortable: false },
          { text: "Trabajador", value: "WorkerName", sortable: true },
          { text: "Versión", value: "CrdVersion", sortable: true },
          { text: "Código", value: "CrdCode", sortable: true },
          { text: "Area", value: "AreName", sortable: true },
        ],
      },
      configPanel: {
        headers: [
          { text: "ID", value: "CrdID", sortable: false },
          { text: "Documento", value: "PrsDocumentNumber", sortable: false },
          { text: "Trabajador", value: "WorkerName", sortable: true },
        ],
      },
    };
  },
  methods: {
    addWorker() {
      console.log(this.selectionBefore);
      this.selectionBefore.forEach((element) => {
        console.log(element);
        if (this.itemsCard.find((x) => x == element) == null) this.itemsCard.push(element);
      });
    },
    selectedWorker(items) {
      this.selectionBefore = items;
    },
    printEvent() {
      const preview = document.querySelector('img');
      
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        // convierte la imagen a una cadena en base64
        preview.src = reader.result;
      }, false);

      
    this.$fun.alert('Seguro de Imprimir?','question').then((r)=>{
      if(r.value)
      _sCardService.save(this.itemsCard).then((data) => {
        console.log('aaaaaaaa ', data.data);
        //reader.readAsDataURL(data);
        this.$fun.downloadFile(data.data, this.$const.TypeFile.PDF, "Fotocheck");
        this.itemsCard = [];
        this.$refs.gridWorker.refresh();
      });
    })
     
    },
    removeEvent(value) {
      if (this.selected.length == 1) {
        this.itemsCard = this.itemsCard.filter((x) => x != this.selected[0]);
      }
    },
    clear() {
      this.itemsCard = [];
    },
    rowClick: function(item, row) {
      if (true) {
        this.selected = [item];
        this.row = Object.assign({}, item);
      } else {
        if (
          this.selected.find((x) => {
            return x == item;
          }) != null
        )
          this.selected = this.selected.filter((x) => {
            return x != item;
          });
        else {
          this.selected.push(item);
          this.row = Object.assign({}, item);
        }
      }
    },
    assignWorker(worker) {
      if (this.itemsCard.find((x) => x == worker) == null) this.itemsCard.push(worker);
    },
  },
  created() {},
  watch: {},
};
</script>
